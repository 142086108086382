// import { CustomLink } from "data/types";
// import React, { FC } from "react";
// import twFocusClass from "utils/twFocusClass";
// import Link from "components/Link";

// const DEMO_PAGINATION: CustomLink[] = [
//   {
//     label: "1",
//     href: "/",
//   },
//   {
//     label: "2",
//     href: "/",
//   },
//   {
//     label: "3",
//     href: "/",
//   },
//   {
//     label: "4",
//     href: "/",
//   },
// ];

// export interface PaginationProps {
//   className?: string;
//   count?: number;
// }

// const Pagination: FC<PaginationProps> = ({ className = "", count }) => {
//   const renderItem = (pag: CustomLink, index: number) => {
//     if (index === 0) {
//       // RETURN ACTIVE PAGINATION
//       return (
//         <span
//           key={index}
//           className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
//         >
//           {pag.label}
//         </span>
//       );
//     }
//     // RETURN UNACTIVE PAGINATION
//     return (
//       <Link
//         key={index}
//         className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
//         href={pag.href}
//       >
//         {pag.label}
//       </Link>
//     );
//   };

//   return (
//     <nav
//       className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
//     >
//       {DEMO_PAGINATION.map(renderItem)}
//     </nav>
//   );
// };

// export default Pagination;
// import { useLanguage } from '../store/languageContext';
import twFocusClass from "utils/twFocusClass";

const Pagination = ({ currentPage, totalPages, onPageChange }: any) => {
  // const { translations } = useLanguage();
  const getPaginationItems = () => {
    const items = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (currentPage <= 3) {
      endPage = Math.min(totalPages, 5);
    } else if (currentPage >= totalPages - 2) {
      startPage = Math.max(1, totalPages - 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <li
          key={i}
          className={`inline-flex mr-1 w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${i === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(i)}>
          <a className="page-link">
            {i}
          </a>
        </li>
      );
    }

    if (startPage > 1) {
      items.unshift(
        <li key="start-ellipsis" className="inline-flex mr-1 w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 disabled">
          <span className="page-link">...</span>
        </li>
      );
      items.unshift(
        <li key={1} className="inline-flex mr-1 w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700" onClick={() => onPageChange(1)}>
          <a className="page-link">
            1
          </a>
        </li>
      );
    }
    if (endPage < totalPages) {
      items.push(
        <li key="end-ellipsis" className="inline-flex mr-1 w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 disabled">
          <span className="page-link">...</span>
        </li>
      );
      items.push(
        <li key={totalPages} className="inline-flex mr-1 w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700" onClick={() => onPageChange(totalPages)}>
          <a className="page-link">
            {totalPages}
          </a>
        </li>
      );
    }

    return items;
  };

  return (
    <>
      <ul className="pagination justify-content-center">
        <li className={`inline-flex mr-1 w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${currentPage === 1 ? 'disabled' : ''}`}>
          <a className="page-link" onClick={() => onPageChange(currentPage - 1)}>
            {'<'}
          </a>
        </li>
        {getPaginationItems()}
        <li className={`inline-flex mr-1 w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a className="page-link" onClick={() => onPageChange(currentPage + 1)}>
            {'>'}
          </a>
        </li>
      </ul>
    </>
  );
};

export default Pagination;