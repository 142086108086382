import React, { FC, useEffect } from "react";
import Card2 from "components/Card2/Card2";
import Card6 from "components/Card6/Card6";
import HeaderFilter from "./HeaderFilter";
import { getAllPosts } from "api/posts";
import Loading from "components/Button/Loading";

export interface SectionMagazine1Props {
  heading?: string;
  className?: string;
}

const SectionMagazine1: FC<SectionMagazine1Props> = ({
  heading = "Latest Articles 🎈 ",
  className = "",
}) => {

  const [posts, setPosts] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getAllPosts('0', '4', 'desc', 'createdAt', 'en')
      .then((res) => {
        if (res.success) {
          setPosts(res?.body?.rows || []);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={`nc-SectionMagazine1 ${className}`}>
      <HeaderFilter heading={heading} />
      {loading && <Loading />}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8">
        {posts[0] && <Card2 size="large" post={posts[0]} />}
        <div className="grid gap-6 md:gap-8">
          {posts
            ?.map((item: any, index: number) => (
              index > 0 && <Card6 key={posts.id} post={item} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SectionMagazine1;
