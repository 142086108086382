import React, { FC, useState } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Textarea from "components/Textarea/Textarea";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { postComment } from "api/posts";
import { addUser } from "api/user";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export interface SingleCommentFormProps {
  className?: string;
  onClickSubmit?: () => void;
  onClickCancel?: () => void;
  textareaRef?: React.MutableRefObject<null>;
  defaultValue?: string;
  rows?: number;
  blogId?: string;
}

const SingleCommentForm: FC<SingleCommentFormProps> = ({
  className = "mt-5",
  onClickSubmit,
  onClickCancel,
  textareaRef,
  defaultValue = "",
  rows = 4, blogId
}) => {
  const [commentAdding, setCommentAdding] = useState<boolean>(false);
  const [comment, setComment] = useState<any>({});
  const [error, setError] = useState('');

  const addComment = () => {
    setCommentAdding(true);
    const name = comment.name.split(' ');
    let firstName = '';
    let lastName = '';
    if (name.length > 1) {
      firstName = name[0];
      lastName = name[1];
    } else {
      lastName = name[0];
    }
    addUser({
      firstName,
      lastName,
      email: comment.email
    })
      .then((userRes) => {
        if (userRes.success) {
          postComment({
            content: comment.content,
            postId: blogId,
            userId: userRes.body?.[0].id
          })
            .then((res) => {
              if (res.success) {
                if (onClickSubmit) {
                  onClickSubmit()
                }
                setComment({});
              }

              console.log(res);
            })
            .catch((err) => console.log(err))
            .finally(() => setCommentAdding(false));
        }
      })
      .catch((err) => {
        console.log(err);
        setCommentAdding(false);
      });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setComment({ ...comment, [name]: value });
    if (name === 'email') {
      if (emailRegex.test(value)) {
        setError('');
      } else {
        setError('Invalid email address');
      }
    }
  };

  return (
    <form action="#" className={`nc-SingleCommentForm ${className}`}>
      <Input
        name="name"
        placeholder="Name"
        required={true}
        onChange={handleChange}
        style={{ marginBottom: '10px' }}
      />
      <Input
        name="email"
        placeholder="Email"
        required={true}
        onChange={handleChange}
        style={{ marginBottom: '10px' }}
      />
      <Textarea
        placeholder="Add to discussion"
        ref={textareaRef}
        required={true}
        defaultValue={defaultValue}
        rows={rows}
        name="content"
        onChange={handleChange}
      />
      <div className="mt-2 space-x-3">
        <ButtonPrimary disabled={commentAdding || JSON.stringify(comment) == '{}' || error != ''} onClick={addComment} type="submit">
          Submit
        </ButtonPrimary>
        <Button type="button" pattern="white" onClick={onClickCancel}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default SingleCommentForm;
