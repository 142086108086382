import React, { FC, useEffect } from "react";
import Heading from "components/Heading/Heading";
import Card11 from "components/Card11/Card11";
import { getAllPosts } from "api/posts";

const SingleRelatedPosts: FC = () => {
  const [relatedPostsData, setRelatedPostsData] = React.useState<any>([]);

  useEffect(() => {
    getAllPosts('0', '4', 'desc', 'createdAt', 'en')
      .then((res) => {
        if (res.success) {
          setRelatedPostsData(res?.body?.rows || []);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    relatedPostsData?.length > 0 ?
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-28">
        <div className="container">
          <div>
            <Heading
              className="mb-10 text-neutral-900 dark:text-neutral-50"
              desc=""
            >
              Related posts
            </Heading>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
              {relatedPostsData?.map((post: any) => (
                <Card11 key={post.id} post={post} />
              ))}
            </div>
          </div>
        </div>
      </div>
      : null
  );
};

export default SingleRelatedPosts;
