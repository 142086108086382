import React, { FC, useEffect } from "react";
import Heading from "components/Heading/Heading";
import Card4 from "components/Card4/Card4";
import MySlider from "components/MySlider";
import { getAllPosts } from "api/posts";
import Loading from "components/Button/Loading";

export interface SectionSliderPostsProps {
  className?: string;
  heading: string;
  subHeading?: string;
  postCardName?: "card4" | "card7" | "card9" | "card10" | "card10V2" | "card11";
  perView?: 2 | 3 | 4;
}

const SectionSliderPosts: FC<SectionSliderPostsProps> = ({
  heading,
  subHeading,
  className = "",
  perView = 4,
}) => {
  const [posts, setPosts] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getAllPosts('0', '10', 'desc', 'createdAt', 'en', { category: 'health' })
      .then((res) => {
        if (res.success) {
          setPosts(res?.body?.rows || []);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={`nc-SectionSliderPosts ${className}`}>
      <Heading desc={subHeading} isCenter>
        {heading}
      </Heading>
      {loading && <Loading />}
      <MySlider
        data={posts}
        renderItem={(item, indx) => <Card4 key={indx} post={item} />}
        itemPerRow={perView}
      />
    </div>
  );
};

export default SectionSliderPosts;
