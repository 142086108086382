import React, { FC } from "react";
import Image from "components/Image";

export interface PostFeaturedMediaProps {
  className?: string;
  post: any;
  isHover?: boolean;
}

const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({
  className = "w-full h-full",
  post,
}) => {
  const { imageUrl } = post;


  return (
    <div className={`nc-PostFeaturedMedia relative ${className}`}>
      <Image
        alt="featured"
        fill
        className="object-cover"
        src={imageUrl}
        sizes="(max-width: 600px) 480px, 800px"
      />
    </div>
  );
};

export default PostFeaturedMedia;
