import React, { FC } from "react";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import Link from "components/Link";
import Image from "components/Image";

export interface Card6Props {
  className?: string;
  post: any;
}

const Card6: FC<Card6Props> = ({ className = "h-full", post }) => {
  const { title, imageUrl, categories } =
    post;

  return (
    <div
      className={`nc-Card6 relative flex group flex-row items-center sm:p-4 sm:rounded-3xl sm:bg-white sm:dark:bg-neutral-900 sm:border border-neutral-200 dark:border-neutral-700 ${className}`}
    >
      <Link href={`/single/${post.id}`} className="absolute inset-0 z-0"></Link>
      <div className="flex flex-col flex-grow">
        <div className="space-y-3 mb-4">
          <CategoryBadgeList categories={[categories[0]]} />
          <h2 className={`block font-semibold text-sm sm:text-base`}>
            <Link href={`/single/${post.id}`} className="line-clamp-2" title={title}>
              {title}
            </Link>
          </h2>
        </div>
        <div className="flex items-center flex-wrap justify-between mt-auto">
          <PostCardLikeAndComment className="relative" likeCount={post?.likes.length} commentCount={post?.comments?.length} />
          <PostCardSaveAction className="relative" readingTime={5} />
        </div>
      </div>

      <Link
        href={`/single/${post.id}`}
        className={`block relative flex-shrink-0 w-24 h-24 sm:w-40 sm:h-full ml-3 sm:ml-5 rounded-2xl overflow-hidden z-0`}
      >
        <Image
          sizes="(max-width: 600px) 180px, 400px"
          className="object-cover w-full h-full"
          fill
          src={imageUrl}
          alt={title}
        />
      </Link>
    </div>
  );
};

export default Card6;
