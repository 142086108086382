import React, { FC, useEffect } from "react";
import Card2 from "components/Card2/Card2";
import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card11 from "components/Card11/Card11";
import { getAllPosts } from "api/posts";
import Loading from "components/Button/Loading";

export interface SectionMagazine2Props extends SectionMagazine1Props { }

const SectionMagazine2: FC<SectionMagazine2Props> = ({
  heading = "🎈 Latest Articles",
  className,
}) => {
  const [posts, setPosts] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    getAllPosts('0', '7', 'desc', 'createdAt', 'en', { category: 'lifestyle' })
      .then((res) => {
        if (res.success) {
          setPosts(res?.body?.rows || []);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const first = posts[0];
  const second = posts.slice(1, 4);
  const third = posts.slice(4, 7);

  return (
    <div className={`nc-SectionMagazine2 ${className}`}>
      <HeaderFilter heading={heading} />

      {!posts.length && <span>Nothing we found!</span>}
      {loading && <Loading />}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <div className="grid gap-6">
          {second
            ?.map((item: any) => {
              return (
                <Card11 ratio="aspect-w-5 aspect-h-3" key={item.id} post={item} />
              );
            })}
        </div>
        <div className="lg:col-span-2">
          {first && <Card2 size="large" post={first} />}
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-1 md:col-span-3 xl:col-span-1">
          {third
            ?.map((item: any) => {
              return (
                <Card11 ratio="aspect-w-5 aspect-h-3" key={item.id} post={item} />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SectionMagazine2;
