import React, { FC } from "react";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import SingleTitle from "./SingleTitle";
import SingleMetaAction2 from "./SingleMetaAction2";

export interface SingleHeaderProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
  post?: any
}

const SingleHeader: FC<SingleHeaderProps> = ({
  titleMainClass,
  className = "",
  post
}) => {
  return (
    <>
      <div className={`nc-SingleHeader ${className}`}>
        <div className="space-y-5">
          <CategoryBadgeList
            itemClass="!px-3"
            categories={post?.categories || []}
          />
          <SingleTitle
            mainClass={titleMainClass}
            title={post?.title || ''}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
            <div />
            <SingleMetaAction2 commentCount={post?.comments?.length || 0} likeCount={post?.likes?.length || 0} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleHeader;
