import React, { FC } from "react";
import CommentCard from "components/CommentCard/CommentCard";

export interface SingleCommentListsProps { comments: any }

const SingleCommentLists: FC<SingleCommentListsProps> = ({ comments }) => {
  return (
    <ul className="nc-SingleCommentLists space-y-5">
      {comments.map((comment: any) => (
        <CommentCard key={comment.id} comment={comment} />
      ))}
    </ul>
  );
};

export default SingleCommentLists;
