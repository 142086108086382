import SectionLargeSlider from "app/(home)/SectionLargeSlider";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors"; import SectionSliderPosts from "components/Sections/SectionSliderPosts";
import SectionMagazine1 from "components/Sections/SectionMagazine1";
import SectionAds from "components/Sections/SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionVideos from "components/Sections/SectionVideos";
import SectionLatestPosts from "components/Sections/SectionLatestPosts";
import SectionMagazine2 from "components/Sections/SectionMagazine2";

const PageHome = () => {
  return (
    <div className="nc-PageHome relative">
      <div className="container relative">
        <SectionLargeSlider
          className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
        />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewAuthors
            heading="Explore environment related blogs"
            subHeading="Say hello to the latest environment related blogs"
          />
        </div>
        <SectionSliderPosts
          className="py-16 lg:py-28"
          postCardName="card9"
          heading="Explore latest health articles"
          subHeading="Click on each to see the details article"
        />

        <SectionMagazine1 className="py-16 lg:py-28" />
        <SectionAds />

      </div>


      <div className="container ">

        <SectionMagazine2
          className="py-16 lg:py-24"
          heading="Life styles 🎨 "
        />

        <SectionSubscribe2 className="pt-16 lg:pt-28" />

        <SectionVideos className="py-16 lg:py-28" />

        <SectionLatestPosts className="pb-16 lg:pb-28" />
      </div>
    </div>
  );
};

export default PageHome;
