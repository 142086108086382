class TokenService {
  getRefreshToken() {
    return localStorage?.getItem('refresh_token') ?? '';
  }

  getAccessToken() {
    return localStorage?.getItem('token') ?? '';
  }

  updateAccessToken(token: string) {
    localStorage.setItem('token', token);
  }
}

export default new TokenService();