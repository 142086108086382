import axios from 'axios';
import toast from 'react-hot-toast';

import TokenService from './tokenService';

// Create a variable to keep track of whether a token refresh is in progress
let isRefreshing = false;
let refreshSubscribers: any[] = [];

// Function to add a subscriber to the queue
function subscribeToTokenRefresh(callback: (token: string) => void) {
  refreshSubscribers.push(callback);
}

// Function to process the queue and refresh the token
function onRefreshed(newToken: string) {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
  isRefreshing = false;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth' && err.response) {
      if (
        (err.response?.status === 403 || err.response?.status === 401) &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const tokenParams = new URLSearchParams();
            tokenParams.append('refresh_token', TokenService.getRefreshToken());
            const tokenRes = { access_token: '' }; // TODO update with actual token

            TokenService.updateAccessToken(tokenRes.access_token);
            onRefreshed(tokenRes.access_token);

            // Retry original request
            originalConfig.headers['Authorization'] = `Bearer ${tokenRes.access_token}`;
            return axios(originalConfig);
          } catch (error) {
            return Promise.reject(error);
          }
        }

        return new Promise((resolve) => {
          subscribeToTokenRefresh((newToken: string) => {
            originalConfig.headers['Authorization'] = `Bearer ${newToken}`;
            resolve(axios(originalConfig));
          });
        });
      }
    }
    toast.error(`${err.name ? err.name + ':' : ''} ${err.response?.data?.error ?? err.message}`, {
      position: 'bottom-left'
    });
    if (err.response?.status === 403) {
      toast.remove();
      toast.error(`${err.name ? err.name + ':' : ''} ${err?.message ?? err.error}`, {
        position: 'bottom-left'
      });
      localStorage.clear();
      window.location.href = '/home';
    }
    return Promise.reject(err);
  }
);

export default instance;