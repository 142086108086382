import React, { FC, useEffect, useState } from "react";
import Card3 from "components/Card3/Card3";
import Heading from "components/Heading/Heading";
import WidgetTags from "components/WidgetTags/WidgetTags";
import WidgetCategories from "components/WidgetCategories/WidgetCategories";
import Pagination from "components/Pagination/Pagination";
import { getAllPosts } from "api/posts";
import Loading from "components/Button/Loading";

export interface SectionLatestPostsProps {
  gridClass?: string;
  className?: string;
  heading?: string;
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  heading = "Latest Articles 🎈",
  gridClass = "",
  className = "",
}) => {

  const [posts, setPosts] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setLoading(true);
    getAllPosts((currentPage - 1).toString(), '5', 'desc', 'createdAt', 'en')
      .then((res) => {
        if (res.success) {
          setPosts(res?.body?.rows || []);
          setCount(res?.body?.count || 0);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [currentPage]);

  const onClickNextPage = (page: number) => {
    setCurrentPage(page)
  }

  console.log('current page', currentPage);


  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          <Heading>{heading}</Heading>
          <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
            {posts.map((post: any) => <Card3 key={post.id} post={post} />)}
          </div>
          {loading && <Loading />}
          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination currentPage={0} totalPages={Math.ceil(count / 10)} onPageChange={onClickNextPage} />
          </div>
        </div>
        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
          <WidgetTags />
          <WidgetCategories />
          {/* <WidgetPosts /> */}
        </div>
      </div>
    </div>
  );
};

export default SectionLatestPosts;
