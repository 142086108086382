import MyRoutes from "./routers";
import { Toaster } from 'react-hot-toast';
import { LanguageProvider } from './store/languageContext';

function App() {
  return (
    <LanguageProvider>
    <div className="bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
      <MyRoutes />
      <Toaster
          toastOptions={{
            success: {
              style: {
                background: '#2e7d32',
                color: '#fff'
              },
              duration: 5000
            },
            error: {
              style: {
                background: '#ff4b4b',
                color: '#fff'
              },
              duration: 5000
            },
            loading: {
              style: {
                background: '#007FFF',
                color: '#fff'
              },
              duration: 10000
            }
          }}
        />
    </div>
    </LanguageProvider>
  );
}

export default App;
