import { getEditorPick } from "api/posts";
import Loading from "components/Button/Loading";
import CardLarge1 from "components/CardLarge1/CardLarge1";
import Heading from "components/Heading/Heading";
import { PostDataType } from "data/types";
import React, { FC, useEffect, useState } from "react";

export interface SectionLargeSliderProps {
  className?: string;
  heading?: string;
  // posts: PostDataType[];
}

const SectionLargeSlider: FC<SectionLargeSliderProps> = ({
  // posts,
  heading = "Editor's pick",
  className = "",
}) => {
  const [indexActive, setIndexActive] = useState(0);
  const [posts, setPosts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => { 
    const fetchData = async () => {
      try{
        setLoading(true);
        const response = await getEditorPick('The Super Bowl rumble,Exploring the Rise of Veganism in America,Embracing Sustainability in Hawaii');
        setPosts(response.body);
      } catch (error) { 
        console.log('error', error);
      } finally {
        setLoading(false);
      }
     
    }
    fetchData()
  },[])

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= posts.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return posts.length - 1;
      }
      return state - 1;
    });
  };

  return (
    <div className={`nc-SectionLargeSlider relative ${className}`}>
      {!!heading && <Heading>{heading}</Heading>}
      {loading && <Loading />}
      {posts?.map((item: any, index: number) => {
        if (indexActive !== index) return null;
        return (
          <CardLarge1
            key={index}
            onClickNext={handleClickNext}
            onClickPrev={handleClickPrev}
            post={item}
          />
        );
      })}
    </div>
  );
};

export default SectionLargeSlider;
