import React, { FC, useEffect } from "react";
import Heading from "components/Heading/Heading";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import MySlider from "components/MySlider";
import { getAllPosts } from "api/posts";
import Loading from "components/Button/Loading";

export interface SectionSliderNewAuthorsProps {
  className?: string;
  heading: string;
  subHeading: string;
  itemPerRow?: number;
}

const SectionSliderNewAuthors: FC<SectionSliderNewAuthorsProps> = ({
  heading = "Suggestions for discovery",
  subHeading = "Popular places to recommends for you",
  className = "",
  itemPerRow = 5,
}) => {
  const [posts, setPosts] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getAllPosts('0', '10', 'desc', 'createdAt', 'en', { category: 'environment' })
      .then((res) => {
        if (res.success) {
          setPosts(res?.body?.rows || []);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={`nc-SectionSliderNewAuthors ${className}`}>
      <Heading desc={subHeading} isCenter>
        {heading}
      </Heading>
      {loading && <Loading />}
      <MySlider
        itemPerRow={itemPerRow}
        data={posts}
        renderItem={(item, index) => (
          <CardAuthorBox2 key={index} post={item} />
        )}
      />
    </div>
  );
};

export default SectionSliderNewAuthors;
