import React, { FC } from "react";
import Avatar from "components/Avatar/Avatar";
import NcImage from "components/NcImage/NcImage";
import Link from "components/Link";

export interface CardAuthorBox2Props {
  className?: string;
  post: any;
}

const CardAuthorBox2: FC<CardAuthorBox2Props> = ({
  className = "",
  post,
}) => {
  const { id, imageUrl } = post;
  return (
    <Link
      href={`/single/${id}`}
      className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-800 rounded-3xl ${className}`}
    >
      <div className="relative flex-shrink-0 ">
        <div>
          <NcImage
            alt="author"
            containerClassName="flex aspect-w-7 aspect-h-5 w-full h-0"
            src={imageUrl || ""}
            fill
            sizes="(max-width: 600px) 480px, 33vw"
          />
        </div>
      </div>

      <div className="-mt-8 m-8 text-center">
        <Avatar
          containerClassName="ring-2 ring-white"
          sizeClass="w-16 h-16 text-2xl"
          radius="rounded-full"
          imgUrl={''}
          userName={post.title}
        />
        <div className="mt-3">
          <h2 className={`text-base font-medium`}>
            <span className="line-clamp-2">{post.title}</span>
          </h2>
        </div>
      </div>
    </Link>
  );
};

export default CardAuthorBox2;
