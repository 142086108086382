import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface LanguageContextType {
  language: string;
  setLanguage: (newLanguage: string) => void;
  translations: any;
  setTranslations: (data: any) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState<string>('');
  const [translations, setTranslations] = useState<any>({});

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.languages[0];
    const lang = browserLanguage.split('-')[0];
    setLanguage(lang);
  }, []);

  useEffect(() => {
    if (language) {
      const loadDependencies = async () => {
        // const translationData = await import(`../localization/${language}`);
        // setTranslations(translationData);
      };

      loadDependencies();
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translations, setTranslations }}>
      {children}
    </LanguageContext.Provider>
  );
};