import React, { useEffect, useState } from "react";
import NcImage from "components/NcImage/NcImage";
import SingleHeader from "app/(singles)/SingleHeader";
import Layout from "../layout";
import { getPostById } from "api/posts";
import { useParams } from "react-router-dom";
import SingleContent from "app/(singles)/SingleContent";
import SingleRelatedPosts from "app/(singles)/SingleRelatedPosts";
import Loading from "components/Button/Loading";

const PageSingle = () => {
  const { blogId } = useParams();
  const [post, setPost] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    if (blogId) {
      try {
        setLoading(true);
        const response = await getPostById(blogId);
        setPost(response.body);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();

  }, [blogId]);

  return (
    <Layout>
      {loading && <Loading />}
      <div className={`nc-PageSingle pt-8 lg:pt-16`}>
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <SingleHeader post={post} />
          </div>
        </header>

        {/* FEATURED IMAGE */}
        <NcImage
          alt="single"
          containerClassName="container my-10 sm:my-12"
          className="w-full rounded-xl"
          src={post?.imageUrl}
          width={1260}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />
      </div>
      <div className="container mt-10">
        <SingleContent post={post} fetchData={() => fetchData()} />
      </div>

      {/* RELATED POSTS */}
      <SingleRelatedPosts />
    </Layout>
  );
};

export default PageSingle;
