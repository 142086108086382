import React, { FC, useRef, useState } from "react";
import Avatar from "components/Avatar/Avatar";
import SingleCommentForm from "app/(singles)/SingleCommentForm";
export interface CommentType {
  id: number;
  createdAt: string;
  content: string;
  postId: number;
  user: {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
  }
}

export interface CommentCardProps {
  className?: string;
  comment?: CommentType;
  size?: "large" | "normal";
}

const CommentCard: FC<CommentCardProps> = ({
  className = "",
  comment,
  size = "large",
}) => {

  const textareaRef = useRef(null);
  const [isReplying, setIsReplying] = useState(false);

  const openReplyForm = () => {
    setIsReplying(true);
    setTimeout(() => {
      textareaRef.current && (textareaRef.current as any).focus();
    }, 100);
  };
  const closeReplyForm = () => {
    setIsReplying(false);
  };

  const renderCommentForm = () => {
    return (
      <SingleCommentForm
        textareaRef={textareaRef}
        onClickSubmit={closeReplyForm}
        onClickCancel={closeReplyForm}
        className="flex-grow"
      />
    );
  };

  return (
    <>
      <div className={`nc-CommentCard flex ${className}`}>
        <Avatar
          sizeClass={`h-6 w-6 text-base ${size === "large" ? "sm:text-lg sm:h-8 sm:w-8" : ""
            }`}
          radius="rounded-full"
          containerClassName="mt-4"
          userName={`${comment?.user?.firstName}`}
        />
        <div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-neutral-200 rounded-xl sm:ml-3 sm:text-base dark:border-neutral-700">
          <div className="relative flex items-center pr-6">
            <div
              className="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100"

            >
              {`${comment?.user?.firstName || ''} ${comment?.user?.lastName || ''}`}
            </div>
            {comment?.createdAt && <span className="mx-2">·</span>}

            <span className="text-neutral-500 dark:text-neutral-400 text-xs line-clamp-1 sm:text-sm">
              {comment?.createdAt?.split("T")[0]}
            </span>
          </div>

          {/* CONTENT */}
          <span className="block text-neutral-700 mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-neutral-300">
            {comment?.content}
          </span>
        </div>
      </div>

    </>
  );
};

export default CommentCard;
